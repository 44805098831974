import { TextArea } from '@planda/design-system'
import React, { ComponentProps } from 'react'
import { useForm } from 'react-hook-form'
import { StaticButton } from './Button'
import { Form } from '@/features/forms/styles'
import { styled } from 'styled-system/jsx'

export type TextAreaProps = Partial<Omit<ComponentProps<'textarea'>, 'onSubmit' | 'title'>>
const TextareaForm = ({ onSubmit, title, children, ...props }: { title?: string, onSubmit: (text: string) => unknown, children?: JSX.Element } & TextAreaProps) => {
    const { register, handleSubmit } = useForm({
        defaultValues: {
            text: ''
        }
    })
    return (
        <StyledForm onSubmit={handleSubmit(({ text }) => {
            onSubmit(text)
        })}>
            {title && <h3 className='text-color'>{title}</h3>}
            <TextArea {...props} {...register('text')}></TextArea>
            {children || <StaticButton type='submit'>Submit</StaticButton>}
        </StyledForm>
    )
}

export default TextareaForm

const StyledForm = styled(Form, {
    base: {
        alignItems: "center"
    }
})