import { Dispatch, ReactNode, SetStateAction, memo } from 'react'
import { animateTransition } from './styles'
import { useTransition } from '@react-spring/web'
import { Cross2Icon } from '@radix-ui/react-icons'
import { IconButton } from 'src/components/common/IconButton'
import { useInternalExternalState } from '@/hooks/useInternalExternalState'
import styledModalComponents from './modalComponents'
import { css } from 'styled-system/css'

const Dialog = ({
    trigger,
    title,
    description,
    children,
    hasPadding = true,
    open,
    setOpen,
    overlayBrightness: dialogBrightness,
    type = 'dialog',
    centerTitle,
}: {
    trigger?: JSX.Element
    title?: ReactNode
    description?: string
    children?: ReactNode
    hasPadding?: boolean
    open?: boolean
    setOpen?: Dispatch<SetStateAction<boolean>>
    overlayBrightness?: 'dark' | 'light' | 'lighter' | 'medium-light'
    type?: 'dialog' | 'alert'
    centerTitle?: boolean
}) => {
    const { internalState: openInternal, setInternalState: setInternalOpen } = useInternalExternalState<boolean>(false, open, setOpen)
    const transition = useTransition(openInternal, animateTransition)

    const { Overlay, Content, Title, Description, Trigger, Root, Close, Portal } = styledModalComponents(type)

    return (
        <Root open={openInternal} onOpenChange={(x) => setInternalOpen(x)}>
            {trigger && <Trigger asChild>{trigger}</Trigger>}
            <Portal forceMount>
                {transition((style, isOpen) => {
                    return (
                        <>
                            {isOpen ? (
                                <Overlay brightness={dialogBrightness} style={{ opacity: style.opacity }}>
                                    <Content hasPadding={hasPadding} style={{ ...style }} forceMount>
                                        {title && <Title className={css({ textAlign: centerTitle ? 'center' : undefined })}>{title}</Title>}
                                        {description && <Description>{description}</Description>}
                                        {children}
                                        <Close asChild>
                                            <IconButton aria-label="Close">
                                                <Cross2Icon />
                                            </IconButton>
                                        </Close>
                                    </Content>
                                </Overlay>
                            ) : null}
                        </>
                    )
                })}
            </Portal>
        </Root>
    )
}

export default memo(Dialog)
