import Dialog from '@/components/common/dialog'
import TextareaForm from '@/components/common/TextareaForm'
import { usePutPlannerItemNLPMutation } from '@/redux/features/api'
import React, { useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

const ItemNLPForm = () => {
    const [putItem] = usePutPlannerItemNLPMutation()
    const [openNlpForm, setOpenNlpForm] = useState(false)
    useHotkeys('shift+mod+p', () => setOpenNlpForm(true))

    return (
        <Dialog open={openNlpForm} setOpen={setOpenNlpForm}>
            <TextareaForm
                title='Create a task or event'
                onSubmit={(text) => {
                    setOpenNlpForm(false)
                    putItem({ text })
                }}
                placeholder='Attend meeting tomorrow from 4pm-5pm'
            />
        </Dialog>
    )
}

export default ItemNLPForm