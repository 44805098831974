import * as DialogPrimitive from '@radix-ui/react-dialog'
import { styled } from 'styled-system/jsx'
import { contentStyle, overlayStyle, titleStyle, descriptionStyle } from './styles'
import { animated } from '@react-spring/web'
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog'
import { memoize } from 'lodash'

const DialogRoot = styled(DialogPrimitive.Root, {
    base: {},
})
const DialogTrigger = DialogPrimitive.Trigger
const DialogOverlay = styled(animated(DialogPrimitive.Overlay), overlayStyle)
const DialogContent = styled(animated(DialogPrimitive.Content), contentStyle)
const DialogTitle = styled(DialogPrimitive.Title, titleStyle)
const DialogDescription = styled(DialogPrimitive.Description, descriptionStyle)
const DialogClose = DialogPrimitive.Close
const DialogPortal = DialogPrimitive.Portal

export const AlertDialogOverlay = styled(animated(AlertDialogPrimitive.Overlay), overlayStyle)
export const AlertDialogRoot = AlertDialogPrimitive.Root
export const AlertDialogTrigger = AlertDialogPrimitive.Trigger
export const AlertDialogContent = styled(animated(AlertDialogPrimitive.Content), contentStyle)
export const AlertDialogTitle = styled(AlertDialogPrimitive.Title, titleStyle)
export const AlertDialogDescription = styled(AlertDialogPrimitive.Description, descriptionStyle)
export const AlertDialogAction = AlertDialogPrimitive.Action
export const AlertDialogCancel = AlertDialogPrimitive.Cancel
export const AlertDialogPortal = AlertDialogPrimitive.Portal

export const MODAL_COMPONENTS = {
    dialog: {
        Overlay: DialogPrimitive.Overlay,
        Content: DialogPrimitive.Content,
        Title: DialogPrimitive.Title,
        Description: DialogPrimitive.Description,
        Trigger: DialogPrimitive.Trigger,
        Root: DialogPrimitive.Root,
        Close: DialogPrimitive.Close,
        Portal: DialogPrimitive.Portal,
    },
    alert: {
        Overlay: AlertDialogPrimitive.Overlay,
        Content: AlertDialogPrimitive.Content,
        Title: AlertDialogPrimitive.Title,
        Description: AlertDialogPrimitive.Description,
        Trigger: AlertDialogPrimitive.Trigger,
        Root: AlertDialogPrimitive.Root,
        Close: AlertDialogPrimitive.Cancel,
        Portal: AlertDialogPrimitive.Portal,
    }
}

const styledModalComponents = memoize((type: 'dialog' | 'alert') => {
    const Overlay = (type === 'dialog' ? DialogOverlay : AlertDialogOverlay) as any // ts-check too long
    const Content = type === 'dialog' ? DialogContent : AlertDialogContent
    const Title = type === 'dialog' ? DialogTitle : AlertDialogTitle
    const Description = type === 'dialog' ? DialogDescription : AlertDialogDescription
    const Trigger = type === 'dialog' ? DialogTrigger : AlertDialogTrigger
    const Root = type === 'dialog' ? DialogRoot : AlertDialogRoot
    const Close = type === 'dialog' ? DialogClose : AlertDialogCancel
    const Portal = type === 'dialog' ? DialogPortal : AlertDialogPortal
    return {
        Overlay,
        Content,
        Title,
        Description,
        Trigger,
        Root,
        Close,
        Portal,
    }
})

export default styledModalComponents
