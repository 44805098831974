import { configureStore, createSelector } from '@reduxjs/toolkit'
import viewModeReducer from '@/redux/features/view-mode/viewModeSlice'
import { apiSlice } from '@/redux/features/api/apiSlice'
import temporaryStateSlice from './features/temporaryStateSlice'
import featureAccessSlice from './features/featureAccessSlice'
import displayPreferencesSlice from './features/displayPreferencesSlice'

export const makeStore = () => {
    return configureStore({
        reducer: {
            viewMode: viewModeReducer,
            temporaryState: temporaryStateSlice,
            featureAccess: featureAccessSlice,
            displayPreferences: displayPreferencesSlice,
            [apiSlice.reducerPath]: apiSlice.reducer,
        },
        middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware().concat(apiSlice.middleware)
        },
    })
}

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']
