'use client'
import { PandaContext, useShowPanda } from '@/components/specific/toolbar/components/useShowPanda'
import ItemNLPForm from '@/features/forms/ItemNLPForm'
import React, { ReactNode } from 'react'
import { UserActionLogContext, useUserActionLog } from 'src/context/useUserActionLog'

const Contexts = ({ children }: { children: ReactNode }) => {
    const pandaProps = useShowPanda()
    const userActionLogProps = useUserActionLog()

    return (
        <>
            <UserActionLogContext.Provider value={userActionLogProps}>
                <PandaContext.Provider value={pandaProps}>
                    {children}
                    <ItemNLPForm />
                </PandaContext.Provider>
            </UserActionLogContext.Provider>
        </>
    )
}

export default Contexts