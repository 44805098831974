import * as React from 'react';
import { styled } from 'styled-system/jsx';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { prettyDate } from '@planda/utils';

const StyledViewport = styled(ToastPrimitive.Viewport, { base: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: 'viewportToastPadding',
    gap: 10,
    width: 390,
    maxWidth: '100vw',
    margin: 0,
    listStyle: 'none',
    zIndex: 2147483647,
    outline: 'none',
} });

const StyledToast = styled(ToastPrimitive.Root, { base: {
    backgroundColor: '$loContrast',
    borderRadius: 6,
    boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    padding: 15,
    display: 'grid',
    gridTemplateAreas: '"title action" "description action"',
    gridTemplateColumns: 'auto max-content',
    columnGap: 15,
    alignItems: 'center',

    '@media (prefers-reduced-motion: no-preference)': {
        '&[data-state="open"]': {
            animation: `toastSlideIn 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
        },
        '&[data-state="closed"]': {
            animation: `fadeOut 100ms ease-in`,
        },
        '&[data-swipe="move"]': {
            transform: 'translateX(var(--radix-toast-swipe-move-x))',
        },
        '&[data-swipe="cancel"]': {
            transform: 'translateX(0)',
            transition: 'transform 200ms ease-out',
        },
        '&[data-swipe="end"]': {
            animation: `toastSwipeOut 100ms ease-out`,
        },
    },
} });

const StyledTitle = styled(ToastPrimitive.Title, {
    base: {
        gridArea: 'title',
        marginBottom: 5,
        fontWeight: 500,
        color: '$gray12',
        fontSize: 15
    }
});

const StyledDescription = styled(ToastPrimitive.Description, {
    base: {
        gridArea: 'description',
        margin: 0,
        color: '$gray11',
        fontSize: 13,
        lineHeight: 1.3
    }
});

const StyledAction = styled(ToastPrimitive.Action, {
    base: {
        gridArea: "action"
    }
});

// Exports
export const ToastProvider = ToastPrimitive.Provider;
export const ToastViewport = StyledViewport;
export const Toast = StyledToast;
export const ToastTitle = StyledTitle;
export const ToastDescription = StyledDescription;
export const ToastAction = StyledAction;
export const ToastClose = ToastPrimitive.Close;

// Your app...
const Box = styled('div', {
    base: {}
});
const Button = styled('button', {
    variants: {
        size: {
            small: {
                fontSize: 12,
                padding: '0 10px',
                lineHeight: '25px',
                height: 25
            }
        },
        variant: {
            violet: {
                backgroundColor: '$loContrast',
                color: '$violet11',
                boxShadow: '0 2px 10px $overlay7',
                '&:hover': {
                    backgroundColor: "$gray3"
                },
                '&:focus': {
                    boxShadow: "0 0 0 2px black"
                }
            },
            green: {
                backgroundColor: '$success2',
                color: '$success11',
                boxShadow: 'inset 0 0 0 1px $success7',
                '&:hover': {
                    boxShadow: "inset 0 0 0 1px $success8"
                },
                '&:focus': {
                    boxShadow: "0 0 0 2px $success8"
                }
            }
        }
    },
    base: {
        all: 'unset',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        padding: '0 15px',
        fontSize: 15,
        lineHeight: 1,
        fontWeight: 500,
        height: 35
    },
    defaultVariants: {
        variant: 'violet',
    },
});

const ToastDemo = () => {
    const [open, setOpen] = React.useState(false);
    const eventDateRef = React.useRef(new Date());
    const timerRef = React.useRef(0);

    React.useEffect(() => {
        return () => clearTimeout(timerRef.current);
    }, []);

    return (
        <ToastProvider swipeDirection="right">
            <Button
                onClick={() => {
                    setOpen(false);
                    window.clearTimeout(timerRef.current);
                    timerRef.current = window.setTimeout(() => {
                        eventDateRef.current = new Date();
                        setOpen(true);
                    }, 100);
                }}
            >
                Add to calendar
            </Button>

            <Toast open={open} onOpenChange={setOpen}>
                <ToastTitle>Scheduled: Catch up</ToastTitle>
                <ToastDescription asChild>
                    <time dateTime={eventDateRef.current.toISOString()}>
                        {prettyDate(eventDateRef.current)}
                    </time>
                </ToastDescription>
                <ToastAction asChild altText="Goto schedule to undo">
                    <Button variant="green" size="small">
                        Undo
                    </Button>
                </ToastAction>
            </Toast>
            <ToastViewport />
        </ToastProvider>
    );
};



export default ToastDemo;
